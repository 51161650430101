import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DPopup", {
    attrs: {
      title: _vm.title,
      comVisible: _vm.visible,
      modalWidth: "800px"
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("div", {
          staticClass: "constainer"
        }, [_c("div", {
          staticClass: "constainer-header"
        }, [_c("a-form-model", {
          ref: "nftForm",
          staticClass: "nft-form",
          attrs: {
            labelCol: {
              style: "width: 85px"
            },
            model: _vm.item
          }
        }, [_c("section", {
          staticClass: "left"
        }, [_c("a-form-model-item", {
          attrs: {
            label: "Upload File",
            rules: {
              required: true
            },
            prop: "itemimgurl"
          }
        }, [_c("UploadNft", {
          attrs: {
            fileNumber: 1,
            memorySize: "102400kb",
            value: _vm.item.fileList,
            disabled: _vm.disabled
          },
          on: {
            preview: _vm.preview,
            change: _vm.handleNftFileChanged
          }
        })], 1), _c("a-form-model-item", {
          attrs: {
            label: "Name",
            rules: {
              required: true
            },
            prop: "itemname"
          }
        }, [_c("a-input", {
          attrs: {
            autoComplete: "off",
            size: "large",
            placeholder: "请输入",
            maxLength: 40,
            disabled: _vm.disabled
          },
          model: {
            value: _vm.item.itemname,
            callback: function callback($$v) {
              _vm.$set(_vm.item, "itemname", $$v);
            },
            expression: "item.itemname"
          }
        })], 1), _c("a-form-model-item", {
          attrs: {
            label: "Description",
            rules: {
              required: false
            },
            prop: "description"
          }
        }, [_c("a-input", {
          attrs: {
            type: "textarea",
            autoComplete: "off",
            size: "large",
            placeholder: "Description of your item",
            maxLength: 300,
            disabled: _vm.disabled
          },
          model: {
            value: _vm.item.description,
            callback: function callback($$v) {
              _vm.$set(_vm.item, "description", $$v);
            },
            expression: "item.description"
          }
        })], 1), _c("a-form-model-item", {
          attrs: {
            label: "Number of copies",
            rules: {
              required: true,
              validator: _vm.validateNumberCopies
            },
            prop: "copies"
          }
        }, [_c("a-input", {
          attrs: {
            autoComplete: "off",
            size: "large",
            placeholder: "Amount of tokens, e.g.10",
            oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
            disabled: _vm.disabled
          },
          model: {
            value: _vm.item.copies,
            callback: function callback($$v) {
              _vm.$set(_vm.item, "copies", $$v);
            },
            expression: "item.copies"
          }
        })], 1)], 1), _c("section", {
          staticClass: "right"
        }, [_c("div", {
          staticClass: "field field-item"
        }, [_c("label", {
          staticClass: "label"
        }, [_c("span", {
          staticClass: "title"
        }, [_vm._v("Properties")]), !_vm.disabled ? _c("a-icon", {
          attrs: {
            type: "plus"
          },
          on: {
            click: function click($event) {
              return _vm.handleModal("properties");
            }
          }
        }) : _vm._e()], 1), _c("div", {
          staticClass: "tips ml40"
        }, [_vm._v(" Textual traits that show up as rectangles ")]), _c("div", {
          staticClass: "ml40 flex-bw"
        }, _vm._l(_vm.item.properties || [], function (it, index) {
          return _c("div", {
            key: index,
            staticClass: "property-box"
          }, [_c("div", {
            staticClass: "p-title"
          }, [_vm._v(" " + _vm._s(it.type) + " ")]), _c("div", {
            staticClass: "p-value"
          }, [_vm._v(" " + _vm._s(it.name) + " ")])]);
        }), 0)]), _c("div", {
          staticClass: "field field-item"
        }, [_c("label", {
          staticClass: "label"
        }, [_c("span", {
          staticClass: "title"
        }, [_vm._v("Levels")]), !_vm.disabled ? _c("a-icon", {
          attrs: {
            type: "plus"
          },
          on: {
            click: function click($event) {
              return _vm.handleModal("levels");
            }
          }
        }) : _vm._e()], 1), _c("div", {
          staticClass: "tips ml40"
        }, [_vm._v(" Textual traits that show up as progress bar ")]), _c("div", {
          staticClass: "ml40 flex-bw"
        }, _vm._l(_vm.item.levels || [], function (it, index) {
          return _c("div", {
            key: index,
            staticClass: "property-box"
          }, [_c("div", {
            staticClass: "p-title"
          }, [_vm._v(" " + _vm._s(it.name) + " ")]), _c("div", {
            staticClass: "p-value flex"
          }, [_vm._v(" " + _vm._s(it.molecule) + " of " + _vm._s(it.denominator) + " "), _c("div", {
            staticClass: "bar"
          }, [_c("div", {
            staticClass: "bg-blue 12",
            style: {
              width: "".concat(Number(it.molecule) > Number(it.denominator) ? 100 : 100 * it.molecule / it.denominator, "%")
            }
          })])])]);
        }), 0)]), _c("div", {
          staticClass: "field field-item"
        }, [_c("label", {
          staticClass: "label"
        }, [_c("span", {
          staticClass: "title"
        }, [_vm._v("Stats")]), !_vm.disabled ? _c("a-icon", {
          attrs: {
            type: "plus"
          },
          on: {
            click: function click($event) {
              return _vm.handleModal("stats");
            }
          }
        }) : _vm._e()], 1), _c("div", {
          staticClass: "tips ml40"
        }, [_vm._v(" Numerical traits that show up as rectangles ")]), _c("div", {
          staticClass: "ml40 flex-bw"
        }, _vm._l(_vm.item.stats || [], function (it, index) {
          return _c("div", {
            key: index,
            staticClass: "property-box"
          }, [_c("div", {
            staticClass: "p-title"
          }, [_vm._v(" " + _vm._s(it.name) + " ")]), _c("div", {
            staticClass: "p-value"
          }, [_vm._v(" " + _vm._s(it.molecule) + " of " + _vm._s(it.denominator) + " ")])]);
        }), 0)])])]), _c("a-form-model-item", {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between"
          }
        }, [_c("a-button", {
          staticClass: "btn btn_l",
          attrs: {
            size: "large",
            disabled: _vm.loading
          },
          on: {
            click: _vm.cancel
          }
        }, [_vm._v(_vm._s("取消"))]), !_vm.disabled ? _c("a-button", {
          staticClass: "btn btn_r",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            size: "large",
            type: "primary",
            loading: _vm.loading
          },
          on: {
            click: _vm.submit
          }
        }, [_vm._v("保存")]) : _vm._e()], 1)], 1), _c("PreviewPic", {
          attrs: {
            isShowPic: _vm.mediaType == "img" && _vm.isShowMedia,
            previewImage: _vm.mediaSource
          },
          on: {
            closePreviewpic: _vm.previewHandleCancel
          }
        }), _c("PreviewMedia", {
          attrs: {
            isShow: _vm.mediaType != "img" && _vm.isShowMedia,
            previewSource: _vm.mediaSource,
            mediaType: _vm.mediaType,
            modalWidth: _vm.mediaType == "audio" ? "380px" : "600px"
          },
          on: {
            closePreview: _vm.previewHandleCancel
          }
        }), _c("Property", {
          attrs: {
            visible: _vm.propertyVisible,
            type: _vm.type,
            source: _vm.item[_vm.type] || []
          },
          on: {
            cancel: _vm.handlePropertyCancel,
            submit: _vm.handlePropertySubmit
          }
        })], 1)];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };