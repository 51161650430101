var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "clearfix",
    class: _vm.classname
  }, [_c("a-upload", {
    attrs: {
      listType: _vm.listType,
      beforeUpload: _vm.beforeUpload,
      customRequest: _vm.handleUpload,
      showUploadList: {
        showRemoveIcon: !_vm.disabled,
        showPreviewIcon: false,
        showDownloadIcon: true
      },
      fileList: _vm.fileList,
      disabled: _vm.disabled,
      accept: _vm.accept
    },
    on: {
      change: _vm.handleChange,
      preview: _vm.previewImg,
      download: _vm.download
    }
  }, [_vm._t("default", function () {
    return [_c("a-button", {
      attrs: {
        disabled: _vm.disabled
      }
    }, [_c("a-icon", {
      attrs: {
        type: "upload"
      }
    }), _vm._v("上传 ")], 1)];
  })], 2), _vm.showTips ? _c("div", {
    staticClass: "img_rules"
  }, [_vm.memorySize ? _c("div", {
    staticClass: "suport_text"
  }, [_vm._v(" 大小: " + _vm._s(_vm.showSize))]) : _vm._e()]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };