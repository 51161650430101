var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DPopup", {
    attrs: {
      title: "白名单",
      comVisible: _vm.visible,
      modalWidth: "420px"
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("div", [_c("div", {
          staticClass: "modal-items property"
        }, [_c("a-form-model", {
          ref: "nftPopForm",
          attrs: {
            model: _vm.item
          }
        }, _vm._l(_vm.item.data, function (it, index) {
          return _c("div", {
            key: index,
            staticClass: "modal-items-tr"
          }, [_c("div", {
            staticClass: "modal-items-td modal-items-cell"
          }, [_c("a-form-model-item", {
            attrs: {
              prop: "data.".concat(index),
              rules: {
                required: true,
                message: " ",
                validator: _vm.validateRepeat
              }
            }
          }, [_c("a-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              placeholder: "",
              disabled: _vm.disabled
            },
            model: {
              value: _vm.item.data[index],
              callback: function callback($$v) {
                _vm.$set(_vm.item.data, index, $$v);
              },
              expression: "item.data[index]"
            }
          })], 1)], 1), index > 0 && !_vm.disabled ? _c("div", {
            staticClass: "modal-items-td"
          }, [_c("a-icon", {
            staticClass: "modal-del",
            attrs: {
              type: "minus"
            },
            on: {
              click: function click($event) {
                return _vm.toggleItems("property", "sub", index);
              }
            }
          })], 1) : _vm._e()]);
        }), 0)], 1), !_vm.disabled ? _c("div", {
          staticClass: "modal-more",
          on: {
            click: function click($event) {
              return _vm.toggleItems("property", "add");
            }
          }
        }, [_vm._v("Add More")]) : _vm._e()]), _c("a-form-item", {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between"
          }
        }, [_c("a-button", {
          staticClass: "btn btn_l",
          attrs: {
            size: "large",
            disabled: _vm.loading
          },
          on: {
            click: _vm.cancel
          }
        }, [_vm._v(_vm._s("取消"))]), !_vm.disabled ? _c("a-button", {
          staticClass: "btn btn_r",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            size: "large",
            type: "primary",
            loading: _vm.loading
          },
          on: {
            click: _vm.submit
          }
        }, [_vm._v("保存")]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };