import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import { LaunchpadApi } from "@/api/index";
import Mixin from "@/utils/mixin";
import { download as _download } from "@/utils/util";
import { ByteConvert } from "@/utils/help";
export default {
  props: {
    /**
     * 类名 classname 用于重置样式
     */
    classname: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * 上传列表的内建样式，支持三种基本样式 text, picture 和 picture-card
     */
    listType: {
      type: String,
      required: false,
      default: "text"
    },
    /**
     * 接受一个数组，用于上传列表渲染
     */
    value: {
      type: [Array, String],
      required: false,
      default: function _default() {
        return [];
      }
    },
    /**
     * 最大上传数量
     */
    max: {
      type: Number,
      required: false,
      default: 1
    },
    // 禁止上传
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    // 限制上传图片大小
    memorySize: {
      type: [Number, String],
      default: function _default() {
        return '';
      }
    },
    // 是否展示提示
    showTips: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    // 是否展示上传按钮
    showUpIcon: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    force: {
      // false时不校验图片尺寸
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    accept: {
      // 允许上传文件列表
      type: String,
      default: '.xlsx'
    }
  },
  data: function data() {
    return {
      fileList: []
    };
  },
  mixins: [Mixin],
  computed: {
    fileSize: function fileSize() {
      return this.memorySize && this.memorySize.split('kb')[0];
    },
    showSize: function showSize() {
      return ByteConvert(this.fileSize * 1024);
    }
  },
  mounted: function mounted() {
    this.init();
  },
  watch: {
    value: function value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.init();
      }
    }
  },
  methods: {
    previewImg: function previewImg(file) {
      this.$emit("previewImg", file.url);
    },
    init: function init() {
      var value = this.value;
      // 处理接受数据和分发
      if (Array.isArray(value)) {
        this.initFileList(value);
      } else if (value) {
        this.initFileList([value]);
      }
    },
    initFileList: function initFileList(arr) {
      var _this = this;
      this.fileList = [];
      arr.forEach(function (item, index) {
        var temp = {
          uid: index,
          status: "done",
          name: item,
          url: item
        };
        _this.fileList.push(temp);
      });
    },
    beforeUpload: function beforeUpload(file) {
      var _this2 = this;
      if (this.fileList.length >= this.max) {
        this.toast("你已达到最大上传数量", "error");
        return false;
      }
      if (this.fileSize && file.size / 1024 > this.fileSize) {
        // 判断上传图片是否超出大小
        this.toast("\u5F53\u524D\u56FE\u7247".concat(this.percentNum(file.size, 1024), "kb,\u8D85\u8FC7\u6700\u5927\u9650\u5236").concat(this.fileSize, "kb"), "error");
        return false;
      }
      return new Promise(function (resolve) {
        var reader = new FileReader();
        reader.onload = function () {
          // 非图片的处理方式
          if (file.type.indexOf("image/") == -1) {
            _this2.fileList.push(file);
            resolve();
            return;
          }
        };
        reader.readAsDataURL(file);
      });
      // 上传之前
    },
    percentNum: function percentNum(denominator, molecular) {
      return parseFloat((denominator / molecular).toFixed(1)); //小数点后两位百分比
    },
    handleChange: function handleChange(info) {
      // 上传状态修改回调
      // console.log('info', info)
      if (info.file.status === "removed") {
        this.fileList = info.fileList;
        this.deliveryData();
      }
      if (info.file.status === "done") {
        this.deliveryData();
      } else if (info.file.status === "error") {
        window.console.error("上传失败");
      }
    },
    deliveryData: function deliveryData() {
      /**
       * 返回一个对象
       */
      this.$emit("input", this.fileList[0]);
      this.$emit("change", this.fileList[0]);
    },
    handleUpload: function handleUpload(data) {
      //   console.log('handleUpload', data);
      var _data$file = data.file,
        uid = _data$file.uid,
        name = _data$file.name,
        status = _data$file.status;
      var _ = this;
      var temp = {
        uid: uid,
        name: name,
        status: status
      };
      // 文件转base64
      var formData = new FormData();
      formData.append("file", data.file);
      // 上传
      LaunchpadApi.Upload(formData).then(function (res) {
        // console.log('上传成功', res)
        _.toast("上传成功", "success", res);
        temp.status = "done";
        temp.url = res.url || '';
        temp.content = res.content || '';
        _.fileList.splice(_.fileList.length - 1, 1, temp);
        _.handleChange({
          file: temp
        });
      }).catch(function (res) {
        _.toast(res.msg || "上传失败", "error");
        temp.status = "error";
        _.fileList.splice(_.fileList.length - 1, 1);
        _.handleChange({
          file: temp
        });
      });
    },
    download: function download(file) {
      // console.log('download', file)
      _download(file.url);
    }
  }
};