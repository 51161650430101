import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DPopup", {
    attrs: {
      title: _vm.title,
      comVisible: _vm.visible,
      modalWidth: "420px"
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_vm.type == "properties" ? _c("div", [_c("div", {
          staticClass: "modal-tips"
        }, [_vm._v("Properties show up in your item’s introduction page to the viewer")]), _c("div", {
          staticClass: "modal-items property"
        }, [_c("div", {
          staticClass: "modal-items-tr"
        }, [_c("div", {
          staticClass: "modal-items-th modal-items-cell"
        }, [_vm._v("Type")]), _c("div", {
          staticClass: "modal-items-th modal-items-cell"
        }, [_vm._v("Name")])]), _c("a-form-model", {
          ref: "nftPopForm",
          attrs: {
            model: _vm.item
          }
        }, _vm._l(_vm.item.data, function (it, index) {
          return _c("div", {
            key: index,
            staticClass: "modal-items-tr"
          }, [_c("div", {
            staticClass: "modal-items-td modal-items-cell"
          }, [_c("a-form-model-item", {
            attrs: {
              prop: "data.".concat(index, ".type"),
              rules: {
                required: true,
                message: " "
              }
            }
          }, [_c("a-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              placeholder: ""
            },
            model: {
              value: it.type,
              callback: function callback($$v) {
                _vm.$set(it, "type", $$v);
              },
              expression: "it.type"
            }
          })], 1)], 1), _c("div", {
            staticClass: "modal-items-td modal-items-cell"
          }, [_c("a-form-model-item", {
            attrs: {
              prop: "data.".concat(index, ".name"),
              rules: {
                required: true,
                message: " "
              }
            }
          }, [_c("a-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              placeholder: ""
            },
            model: {
              value: it.name,
              callback: function callback($$v) {
                _vm.$set(it, "name", $$v);
              },
              expression: "it.name"
            }
          })], 1)], 1), index > 0 ? _c("div", {
            staticClass: "modal-items-td"
          }, [_c("a-icon", {
            staticClass: "modal-del",
            attrs: {
              type: "minus"
            },
            on: {
              click: function click($event) {
                return _vm.toggleItems("property", "sub", index);
              }
            }
          })], 1) : _vm._e()]);
        }), 0)], 1), _c("div", {
          staticClass: "modal-more",
          on: {
            click: function click($event) {
              return _vm.toggleItems("property", "add");
            }
          }
        }, [_vm._v("Add More")])]) : _vm._e(), _vm.type == "stats" || _vm.type == "levels" ? _c("div", [_c("div", {
          staticClass: "modal-tips"
        }, [_vm._v(_vm._s(_vm.type == "levels" ? "Levels" : "Stats") + " show up in your item’s introduction page to the viewer")]), _c("div", {
          staticClass: "modal-items property"
        }, [_c("div", {
          staticClass: "modal-items-tr"
        }, [_c("div", {
          staticClass: "modal-items-th modal-items-cell"
        }, [_vm._v("Name")]), _c("div", {
          staticClass: "modal-items-th modal-items-cell"
        }, [_vm._v("Value")])]), _c("a-form-model", {
          ref: "nftPopForm",
          attrs: {
            model: _vm.item
          }
        }, _vm._l(_vm.item.data, function (it, index) {
          return _c("div", {
            key: index,
            staticClass: "modal-items-tr"
          }, [_c("div", {
            staticClass: "modal-items-td modal-items-cell"
          }, [_c("a-form-model-item", {
            attrs: {
              rules: {
                required: true,
                message: " "
              },
              prop: "data.".concat(index, ".name")
            }
          }, [_c("a-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              placeholder: ""
            },
            model: {
              value: it.name,
              callback: function callback($$v) {
                _vm.$set(it, "name", $$v);
              },
              expression: "it.name"
            }
          })], 1)], 1), _c("div", {
            staticClass: "modal-items-td modal-items-cell"
          }, [_c("a-form-model-item", {
            staticClass: "w67",
            attrs: {
              rules: {
                required: true,
                validator: _vm.validateStatAndLevel,
                message: " "
              },
              prop: "data.".concat(index, ".molecule")
            }
          }, [_c("a-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
              placeholder: ""
            },
            model: {
              value: it.molecule,
              callback: function callback($$v) {
                _vm.$set(it, "molecule", _vm._n($$v));
              },
              expression: "it.molecule"
            }
          })], 1), _c("span", {
            staticClass: "modal-of"
          }, [_vm._v("of")]), _c("a-form-model-item", {
            staticClass: "w67",
            attrs: {
              rules: {
                required: true,
                validator: _vm.validateStatAndLevel,
                message: " "
              },
              prop: "data.".concat(index, ".denominator")
            }
          }, [_c("a-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              oninput: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
              placeholder: ""
            },
            model: {
              value: it.denominator,
              callback: function callback($$v) {
                _vm.$set(it, "denominator", _vm._n($$v));
              },
              expression: "it.denominator"
            }
          })], 1)], 1), index > 0 ? _c("div", {
            staticClass: "modal-items-td"
          }, [_c("a-icon", {
            staticClass: "modal-del",
            attrs: {
              type: "minus"
            },
            on: {
              click: function click($event) {
                return _vm.toggleItems(_vm.type, "sub", index);
              }
            }
          })], 1) : _vm._e()]);
        }), 0)], 1), _c("div", {
          staticClass: "modal-more",
          on: {
            click: function click($event) {
              return _vm.toggleItems(_vm.type, "add");
            }
          }
        }, [_vm._v("Add More")])]) : _vm._e(), _c("a-form-item", {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between"
          }
        }, [_c("a-button", {
          staticClass: "btn btn_l",
          attrs: {
            size: "large",
            disabled: _vm.loading
          },
          on: {
            click: _vm.cancel
          }
        }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
          staticClass: "btn btn_r",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            size: "large",
            type: "primary",
            loading: _vm.loading
          },
          on: {
            click: _vm.submit
          }
        }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };