import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import { deepClone } from '@/utils/help';
export default {
  name: 'property',
  props: {
    type: {
      type: String,
      default: 'property'
    },
    source: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: function visible() {
      if (this.visible) {
        this.item.data = [];
        if (this.source.length == 0) {
          this.toggleItems(this.type, 'add');
        } else {
          this.item.data = deepClone(this.source);
        }
      } else {
        this.loading = false;
      }
    }
  },
  computed: {
    // 计算属性
    title: function title() {
      var str = '';
      switch (this.type) {
        case 'properties':
          str = 'Add Properties';
          break;
        case 'levels':
          str = 'Add Level';
          break;
        case 'stats':
          str = 'Add Stats';
          break;
        case 'whitelist':
          str = 'Add Whitelist';
          break;
      }
      return str;
    }
  },
  data: function data() {
    return {
      editId: '',
      loading: false,
      item: {
        data: []
      }
    };
  },
  methods: {
    // stat和level校验
    validateStatAndLevel: function validateStatAndLevel(rule, value, callback) {
      // console.log('validate',rule, value);
      var fullField = rule.fullField;
      var data = fullField.split('.') || [];
      // const key = data[0] || '';
      var index = data[1] || 0;
      var field = data[2] || '';
      var target = field == 'molecule' ? 'denominator' : 'molecule';
      var targetValue = this.item.data[index][target];
      // console.log(index, field, value, target, targetValue);
      if (!value) {
        return callback(new Error("".concat(field, " is required.")));
      } else if (targetValue) {
        if (field == 'molecule') {
          if (Number(value) > Number(targetValue)) {
            return callback(new Error("".concat(field, " is larger than ").concat(target, ".")));
          }
        } else {
          if (Number(value) < Number(targetValue)) {
            return callback(new Error("".concat(target, " is larger than ").concat(field, ".")));
          }
        }
      }
      return callback();
    },
    cancel: function cancel() {
      this.$emit('cancel');
    },
    submit: function submit() {
      var _this = this;
      this.$refs.nftPopForm.validate(function (valid) {
        // console.log(this.item)
        if (valid) {
          _this.loading = true;
          _this.$emit('submit', _this.type, _this.item.data);
        }
      });
    },
    toggleItems: function toggleItems(name, direction, ind) {
      // console.log('toggleItems', name,direction)
      if (direction == 'sub') {
        this.item.data.splice(ind, 1);
      } else if (direction == 'add') {
        if (name == 'properties') {
          this.item.data.push({
            type: '',
            name: ''
          });
        } else {
          this.item.data.push({
            name: '',
            molecule: '',
            denominator: ''
          });
        }
      }
    },
    close: function close() {
      // console.log('com close2222...')
      this.$emit("close");
    },
    destroyed: function destroyed() {
      //触发关闭页面的回调
      this.close();
    }
  }
};