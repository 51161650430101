import _slicedToArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.splice.js";
import { uploadLaunchpadImg, uploadLaunchpadMedia } from "@/api/common";
import Mixin from "@/utils/mixin";
import { ByteConvert } from "@/utils/help";
export default {
  props: {
    /**
     * 类名 classname 用于重置样式
     */
    classname: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * 上传列表的内建样式，支持三种基本样式 text, picture 和 picture-card
     */
    listType: {
      type: String,
      required: false,
      default: "picture-card"
    },
    /**
     * 是否展示 uploadList, 可设为一个对象，用于单独设定 showPreviewIcon 和 showRemoveIcon
     */
    showUploadList: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * 接受一个数组，用于上传列表渲染
     */
    value: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    /**
     * 最大上传数量--必须是1，不然会有问题
     */
    max: {
      type: Number,
      required: false,
      default: 1
    },
    // 禁止上传
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    // 限制上传图片大小
    memorySize: {
      type: [String, Number],
      default: function _default() {
        return '';
      }
    },
    // 图片像素比例
    imgProportion: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 是否展示提示
    showTips: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    // 是否展示上传按钮
    showUpIcon: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    force: {
      // false时不校验图片尺寸
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    equalSides: {
      // 是否要求等边
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    accept: {
      // 接受的文件类型
      type: String,
      default: '.jpg, .jpeg, .png, .gif, .svg, .mp4, .webm, .mp3, .wav, .ogg, .glb, .gltf'
    }
  },
  data: function data() {
    return {
      fileList: []
      // fileType: 1, // 类型 1:图片 2:音频 3:视频
    };
  },

  mixins: [Mixin],
  computed: {
    imgSize: function imgSize() {
      return this.memorySize && this.memorySize.split('kb')[0];
    },
    showSize: function showSize() {
      return ByteConvert(this.imgSize * 1024);
    }
  },
  mounted: function mounted() {
    console.log('mounted', this.value);
    this.init();
  },
  watch: {
    value: function value(newValue, oldValue) {
      console.log('watch', newValue);
      this.init();
    }
  },
  methods: {
    previewImg: function previewImg(file) {
      this.$emit("preview", file);
    },
    init: function init() {
      var value = this.value;
      // 处理接受数据和分发
      if (Array.isArray(value)) {
        this.initFileList(value);
      }
    },
    initFileList: function initFileList(arr) {
      var _this = this;
      arr.forEach(function (item, index) {
        var temp = {
          uid: index,
          status: "done",
          name: item.name || item.url,
          url: item.url,
          fileType: item.fileType
        };
        _this.fileList.push(temp);
      });
    },
    beforeUpload: function beforeUpload(file) {
      var _this2 = this;
      if (this.fileList.length >= this.max) {
        this.toast("你已达到最大上传数量", "error");
        return false;
      }
      if (this.imgSize && file.size / 1024 > this.imgSize) {
        // 判断上传文件是否超出大小
        this.toast("\u5F53\u524D\u56FE\u7247".concat(this.percentNum(file.size, 1024), "kb,\u8D85\u8FC7\u6700\u5927\u9650\u5236").concat(this.imgSize, "kb"), "error");
        return false;
      }
      // 判断文件类型
      if (!this.isImage(file.name) && !this.isAudio(file.name) && !this.isVideo(file.name)) {
        this.toast("\u6587\u4EF6\u7C7B\u578B\u4EC5\u652F\u6301\u56FE\u7247\u3001\u89C6\u9891\u3001\u97F3\u9891.", "error");
        return false;
      }
      var fileType = this.isImage(file.name) ? 1 : this.isAudio(file.name) ? 2 : 3;
      file.fileType = fileType;
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function (e) {
          var data = e.target.result;
          if (fileType != 1) {
            file.status = "uploading";
            // console.log('this.fileList', this.fileList)
            _this2.fileList.push(file);
            resolve();
            return;
          }

          // 图片才可以校验像素
          var img = new Image();
          img.src = data;
          img.onload = function () {
            // console.log('imgimgimg', img.width, img.height)
            var width = img.width,
              height = img.height; //当前图片宽高
            var _this2$imgProportion = _slicedToArray(_this2.imgProportion, 2),
              targetWidth = _this2$imgProportion[0],
              targetHeight = _this2$imgProportion[1]; //目标宽高
            if (_this2.equalSides && width != height) {
              _this2.toast("\u5F53\u524D\u56FE\u7247\u957F\u4E3A".concat(width, ",\u5BBD\u4E3A").concat(height, "\uFF0C\u5FC5\u987B\u4E3A\u6B63\u65B9\u5F62"), "error");
              return false;
            }
            if (!_this2.force || !_this2.imgProportion.length) {
              _this2.fileList.push(file);
              resolve();
              return;
            }
            if (targetWidth != "" && targetHeight != "") {
              if (width != targetWidth || height != targetHeight) {
                _this2.toast("\u5F53\u524D\u56FE\u7247\u957F\u4E3A".concat(width, ",\u5BBD\u4E3A").concat(height, "\uFF0C\u8BF7\u4E0A\u4F20\u957F\u4E3A").concat(targetWidth, "\uFF0C\u5BBD\u4E3A").concat(targetHeight, "\u7684\u56FE\u7247"), "error");
                return false;
              }
            }
            if (targetWidth == "") {
              if (height != targetHeight) {
                _this2.toast("\u5F53\u524D\u56FE\u7247\u957F\u4E3A".concat(width, ",\u5BBD\u4E3A").concat(height, "\uFF0C\u8BF7\u4E0A\u4F20\u957F\u4E3A\u4E0D\u9650\uFF0C\u5BBD\u4E3A").concat(targetHeight, "\u7684\u56FE\u7247"), "error");
                return false;
              }
            }
            if (targetHeight == "") {
              if (width != targetWidth) {
                _this2.toast("\u5F53\u524D\u56FE\u7247\u957F\u4E3A".concat(width, ",\u5BBD\u4E3A").concat(height, "\uFF0C\u8BF7\u4E0A\u4F20\u957F\u4E3A").concat(targetWidth, "\uFF0C\u5BBD\u4E3A\u4E0D\u9650\u7684\u56FE\u7247"), "error");
                return false;
              }
            }
            file.status = "uploading";
            // console.log('this.fileList', this.fileList)
            _this2.fileList.push(file);
            resolve();
          };
        };
        reader.readAsDataURL(file);
      });
      // 上传之前
    },
    percentNum: function percentNum(denominator, molecular) {
      return parseFloat((denominator / molecular).toFixed(1)); //小数点后两位百分比
    },
    handleChange: function handleChange(info) {
      // 上传状态修改回调
      // console.log('info', info)
      if (info.file.status === "removed") {
        this.fileList = info.fileList;
        this.deliveryData();
      }
      if (info.file.status === "done") {
        this.deliveryData();
      } else if (info.file.status === "error") {
        window.console.error("上传失败");
      }
    },
    deliveryData: function deliveryData() {
      var imgList = [];
      this.fileList.forEach(function (item) {
        imgList.push(item);
      });
      this.fileList = [];

      /**
       * 返回一个组数
       */
      if (this.max === 1) {
        this.$emit("change", imgList[0]);
        // 一张图片的时候 添加双向绑定V-model
        // this.$emit("input", imgList[0]);
      } else {
        // this.$emit("input", imgList);
        this.$emit("change", imgList);
      }
    },
    handleUpload: function handleUpload(data) {
      // console.log(data.file)
      var _data$file = data.file,
        uid = _data$file.uid,
        name = _data$file.name,
        status = _data$file.status,
        fileType = _data$file.fileType;
      var _ = this;
      var temp = {
        uid: uid,
        name: name,
        status: status,
        fileType: fileType
      };
      // 图片转base64
      var formData = new FormData();
      formData.append("file", data.file);
      // 上传
      var method = fileType == 1 ? uploadLaunchpadImg : uploadLaunchpadMedia;
      method(formData).then(function (res) {
        _.toast("上传成功", "success");
        temp.status = "done";
        temp.url = res;
        _.fileList.splice(_.fileList.length - 1, 1, temp);
        _.handleChange({
          file: temp
        });
      }).catch(function (res) {
        _.toast("上传失败:" + (res.msg || ''), "error");
        temp.status = "error";
        _.fileList.splice(_.fileList.length - 1, 1);
        _.handleChange({
          file: temp
        });
      });
    },
    // 是否为图片
    isImage: function isImage(filePath) {
      // 图片后缀
      var types = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'glb', 'gltf']; // 'tiff','webp','bmp','psd',
      // 文件后缀
      var type = this.fileExtension(filePath);
      // 是否包含
      return types.indexOf(type) !== -1;
    },
    // 是否为音频
    isAudio: function isAudio(filePath) {
      // 后缀
      var types = ['mp3', 'wav', 'ogg'];
      // 文件后缀
      var type = this.fileExtension(filePath);
      // 是否包含
      return types.indexOf(type) !== -1;
    },
    // 是否为视频
    isVideo: function isVideo(filePath) {
      // 后缀
      var types = ['mp4', 'webm']; //['avi', 'wmv', 'mpg', 'mpeg', 'mov', 'rm', 'ram', 'swf', 'flv', 'mp4', 'wma', 'avi', 'rm', 'rmvb', 'flv', 'mpg', 'mkv']
      // 文件后缀
      var type = this.fileExtension(filePath);
      // 是否包含
      return types.indexOf(type) !== -1;
    },
    // 获取文件后缀类型
    fileExtension: function fileExtension(filePath) {
      // 获取最后一个.的位置
      var index = filePath.lastIndexOf('.');
      // 获取后缀
      var type = filePath.substr(index + 1);
      // 返回类型
      return type.toLowerCase();
    }
  }
};