var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "clearfix",
    class: _vm.classname
  }, [_c("a-upload", {
    attrs: {
      listType: _vm.listType,
      beforeUpload: _vm.beforeUpload,
      customRequest: _vm.handleUpload,
      showUploadList: _vm.disabled ? {
        showRemoveIcon: false,
        showPreviewIcon: true
      } : _vm.showUploadList,
      fileList: _vm.fileList,
      disabled: _vm.disabled,
      accept: _vm.accept
    },
    on: {
      change: _vm.handleChange,
      preview: _vm.previewImg
    }
  }, [_vm._t("default", function () {
    return [_vm.fileList.length < _vm.max && _vm.showUpIcon ? _c("div", [_c("a-icon", {
      attrs: {
        type: "plus"
      }
    }), _c("div", {
      staticClass: "ant-upload-text"
    }, [_vm._v("上传文件")])], 1) : _vm._e()];
  })], 2), _vm.showTips ? _c("div", {
    staticClass: "img_rules"
  }, [_vm.memorySize ? _c("div", {
    staticClass: "suport_text"
  }, [_vm._v(" 大小: " + _vm._s(_vm.showSize))]) : _vm._e(), _vm.imgProportion.length ? _c("div", {
    staticClass: "suport_text"
  }, [_c("span", [_vm._v(_vm._s(_vm.force ? "" : "建议") + "尺寸: " + _vm._s(_vm.imgProportion[0] + "*" + _vm.imgProportion[1]) + " ")])]) : _vm._e()]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };