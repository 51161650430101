import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.error.cause.js";
import Property from './property.vue';
import UploadNft from './UploadNft.vue';
import { deepClone, objKeySort } from '@/utils/help';
import PreviewMedia from "@/components/PreviewMedia";
export default {
  name: 'launchpadNft',
  components: {
    Property: Property,
    UploadNft: UploadNft,
    PreviewMedia: PreviewMedia
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    source: {
      // 传入的资源数据
      type: Object,
      default: function _default() {}
    },
    index: {
      type: Number,
      default: -1 // -1的时候是新增
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: function visible(newVal) {
      if (newVal) {
        if (this.source && Object.keys(this.source).length > 0) {
          this.item = deepClone(this.source);
          if (this.item.levels) {
            this.item.levels.forEach(function (item) {
              item.molecule = item.level1;
              item.denominator = item.level2;
            });
          }
          if (this.item.stats) {
            this.item.stats.forEach(function (item) {
              item.molecule = item.stats1;
              item.denominator = item.stats2;
            });
          }
          if (this.item.itemimgurl) {
            this.$set(this.item, 'fileList', [{
              url: this.item.itemimgurl,
              fileType: this.item.itemimgtype
            }]);
          } else {
            this.$set(this.item, 'fileList', []);
          }
        } else {
          this.item = {};
        }
      }
    }
  },
  computed: {
    title: function title() {
      if (this.item.id) {
        return 'NFT编辑';
      } else {
        return 'NFT添加';
      }
    }
  },
  data: function data() {
    return {
      editId: '',
      loading: false,
      createForm: this.$form.createForm(this, {
        name: 'launchpad_nft'
      }),
      item: {
        fileList: []
      },
      propertyVisible: false,
      type: '',
      mediaType: 'img',
      mediaSource: '',
      isShowMedia: false
    };
  },
  methods: {
    cancel: function cancel() {
      this.$emit('handleAddCancle');
    },
    submit: function submit() {
      var _this = this;
      this.$refs.nftForm.validate(function (valid) {
        if (valid) {
          // 数据需要整合下
          // 提交的levels和stats内部的字段需要变更下--对应的id字段该删除还是要删除
          var data = deepClone(_this.item);
          delete data.fileList;
          if (data.levels && data.levels.length > 0) {
            data.levels.forEach(function (item) {
              item.level1 = Number(item.molecule);
              item.level2 = Number(item.denominator);
              delete item.molecule;
              delete item.denominator;
            });
          }
          if (data.stats && data.stats.length > 0) {
            data.stats.forEach(function (item) {
              item.stats1 = Number(item.molecule);
              item.stats2 = Number(item.denominator);
              delete item.molecule;
              delete item.denominator;
            });
          }
          data.copies = Number(data.copies);
          // data.fee = Number(data.fee);
          var state = 0;
          if (_this.item.id) {
            state = 2;
            if (JSON.stringify(objKeySort(data)) != JSON.stringify(objKeySort(_this.source))) {
              state = 1;
            }
          }
          data.state = state;
          // console.log(data);
          _this.$emit('handleAddSubmit', _this.index, data);
        }
      });
    },
    handleModal: function handleModal(type) {
      this.type = type;
      this.propertyVisible = true;
    },
    handlePropertyCancel: function handlePropertyCancel() {
      this.propertyVisible = false;
    },
    handlePropertySubmit: function handlePropertySubmit(type, data) {
      this.item[type] = data;
      this.propertyVisible = false;
      // console.log(type, data, this.item)
    },
    // 版税校验
    validateCreatorFee: function validateCreatorFee(rule, value, callback) {
      // console.log('validator', rule, value);
      if (!value) {
        callback(new Error('Creator fee is required.'));
      } else if (Number(value) < 2 || Number(value) > 50) {
        callback(new Error('Creator fee is in 2% ~ 50%.'));
      }
      callback();
    },
    validateNumberCopies: function validateNumberCopies(rule, value, callback) {
      if (!value) {
        callback(new Error('Number of copies is required.'));
      } else if (Number(value) < 1 || Number(value) > 100000) {
        callback(new Error('Number of copies must less than 100000.'));
      }
      callback();
    },
    handleValidate: function handleValidate(field) {
      this.$refs.nftForm.validateField(field);
    },
    handleNftFileChanged: function handleNftFileChanged(file) {
      // console.log(file);
      // 全部先当作一个来处理呗
      var _ref = file || {},
        fileType = _ref.fileType,
        url = _ref.url;
      this.$set(this.item, 'itemimgurl', url);
      this.$set(this.item, 'itemimgtype', fileType);
      if (url) {
        this.$set(this.item, 'fileList', [{
          url: url,
          fileType: fileType
        }]);
      } else {
        this.$set(this.item, 'fileList', []);
      }
      this.handleValidate('itemimgurl');
    },
    preview: function preview(file) {
      var fileType = file.fileType || 1;
      if (fileType == 1) {
        this.mediaType = 'img';
      } else if (fileType == 2) {
        this.mediaType = 'audio';
      } else if (fileType == 3) {
        this.mediaType = 'video';
      }
      // 根据类型来判断下呗
      this.mediaSource = file.url;
      this.isShowMedia = true;
      // console.log(this.mediaType, this.mediaSource, this.isShowMedia)
    },
    previewHandleCancel: function previewHandleCancel() {
      this.isShowMedia = false;
    }
  }
};