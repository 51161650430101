import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import { deepClone } from '@/utils/help';
export default {
  name: 'whitelist',
  props: {
    type: {
      type: String,
      default: 'whitelist'
    },
    source: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: function visible() {
      if (this.visible) {
        this.item.data = [];
        if (this.source.length == 0) {
          this.toggleItems(this.type, 'add');
        } else {
          this.item.data = deepClone(this.source);
        }
      } else {
        this.loading = false;
      }
    }
  },
  data: function data() {
    return {
      editId: '',
      loading: false,
      item: {
        data: []
      }
    };
  },
  methods: {
    validateRepeat: function validateRepeat(rules, text, callback) {
      text = text ? text.trim() : undefined;
      if (!text) {
        callback('不能为空');
      } else if (this.item.data.indexOf(text) !== this.item.data.lastIndexOf(text)) {
        callback('有重复值');
      }
      callback();
    },
    cancel: function cancel() {
      this.$emit('cancel');
    },
    submit: function submit() {
      var _this = this;
      this.$refs.nftPopForm.validate(function (valid) {
        // console.log(this.item)
        if (valid) {
          _this.loading = true;
          _this.$emit('submit', _this.type, _this.item.data);
        }
      });
    },
    toggleItems: function toggleItems(name, direction, ind) {
      // console.log('toggleItems', name,direction)
      if (direction == 'sub') {
        this.item.data.splice(ind, 1);
      } else if (direction == 'add') {
        this.item.data.push('');
      }
    },
    close: function close() {
      // console.log('com close2222...')
      this.$emit("close");
    },
    destroyed: function destroyed() {
      //触发关闭页面的回调
      this.close();
    }
  }
};